import {Button, Container, Paper, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import Confetti from "react-confetti";
import {useNavigate} from "react-router-dom";

export const Qualified = ({answers, setAnswers}) => {

    const navigate = useNavigate();

    // useEffect(() => {
    //     setAnswers({...answers, isQualified: true})
    // }, [])

    return (
        <>
            <Confetti recycle={false}/>
            <Container maxWidth="md" sx={{py: 4}}>
                <Paper sx={{p: 4, borderRadius: 1.5, alignItems: "center", justifyContent: "center"}} variant="outlined">
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        align='center'
                        sx={{ mb: 3 }}
                    >
                        Congratulations!
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                        You are OFFICIALLY qualified for the County Approved Program!
                        <br/>
                        You can receive assistance to reduce your insurance and replace parts of your home for
                        <em> no of pocket cost</em>!
                    </Typography>
                    <Stack alignItems="center">
                        <Button variant="contained" sx={{mt: 3}} onClick={() => navigate('/get-improvement-types')}>
                            Continue
                        </Button>
                    </Stack>
                </Paper>
            </Container>
        </>
    )
}