import './App.css';
import {Button, CssBaseline, ThemeProvider} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import questions from './questions';
import { motion } from 'framer-motion';
import {Question} from "./pages/Question";
import {useEffect, useState} from "react";
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {Lander} from "./pages/Lander";
import {GetPhoneNumber} from "./pages/GetPhoneNumber";
import {GetImprovementTypes} from "./pages/GetImprovementTypes";
import {NotQualified} from "./pages/NotQualified";
import {Qualified} from "./pages/Qualified";
import {VerifyNameAndAddress} from "./pages/VerifyNameAndAddress";
import {PreQualified} from "./pages/PreQualified";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Georgia'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#375e3a',
      dark: '#375e3a',
      light: '#375e3a',
    },
    secondary: {
      main: '#35454f',
      dark: '#253037',
      light: '#5d6a72',
    },
  },
})

function App() {
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (Object.keys(answers).length === 0 && localStorage.getItem('answers')) {
      setAnswers(JSON.parse(localStorage.getItem('answers')))
    }
    else {
      localStorage.setItem('answers', JSON.stringify(answers))
    }
  }, [JSON.stringify(answers)])

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to='/8216692' />} />
            <Route path="/get-phone-number" element={<GetPhoneNumber answers={answers} setAnswers={setAnswers}/>} />
            <Route path="/get-improvement-types" element={<GetImprovementTypes answers={answers} setAnswers={setAnswers}/>} />
            <Route path="/verify-name-and-address" element={<VerifyNameAndAddress answers={answers} setAnswers={setAnswers}/>} />
            <Route path="/not-qualified" element={<NotQualified/>} />
            <Route path="/qualified" element={<Qualified answers={answers} setAnswers={setAnswers}/>} />
            <Route path="/pre-qualified" element={<PreQualified/>} />
            <Route path="/done" element={<Lander answers={answers}/>} />
            {questions.map((question, index) => (
                <Route key={index} path={`${question.id}`} element={
                  answers?.isQualified ? <Navigate to='/qualified' /> :
                      <Question question={question} answers={answers} setAnswers={setAnswers} />
                }/>
            ))}
          </Routes>
        </BrowserRouter>
        {/*<Footer />*/}
      </ThemeProvider>
  );
}

export default App;
