import {Paper, Container, Typography, Button, Stack, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Confetti from "react-confetti";

export const PreQualified = () => {

    const navigate = useNavigate();
    const [isQualified, setIsQualified] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Checking...")

    function firstFunction() {
        setLoadingMessage("Just a moment...")
    }

    function secondFunction() {
        setLoadingMessage("Hang tight...")
    }

    function thirdFunction() {
        setIsQualified(true)
    }

    useEffect(() => {
        setTimeout(() => {
            firstFunction();
            setTimeout(() => {
                secondFunction();
                setTimeout(() => {
                    thirdFunction();
                }, 3000);
            }, 2000);
        }, 2500);
    }, [])

    return (
        <>
            {isQualified && <Confetti recycle={false}/>}
            <Container maxWidth="md" sx={{py: 4}}>
                <Paper sx={{p: 4, borderRadius: 1.5, alignItems: "center", justifyContent: "center"}} variant="outlined">
                    {isQualified ? (
                        <>
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                align='center'
                                sx={{ mb: 2 }}
                            >
                                Congratulations! <br/>
                            </Typography>
                            <Typography
                                variant="h5"
                                align='center'
                                sx={{ mb: 3 }}
                            >
                                You have been pre-qualified for the Country-Assistance Program!
                            </Typography>
                            <Typography variant="body1" align={"center"}>
                                Just one more step and you'll be on your way to getting your home improvement project started...
                            </Typography>
                            <Stack alignItems="center">
                                <Button variant="contained" sx={{mt: 3}} onClick={() => navigate('/get-phone-number')}>
                                    Continue
                                </Button>
                            </Stack>
                        </>
                    ) : (
                        <Stack direction="column" justifyContent="center" alignItems='center'>
                            <CircularProgress />
                            <Typography variant="h5" sx={{mt: 3}}>
                                {loadingMessage}
                            </Typography>
                        </Stack>
                    )}
                </Paper>
            </Container>
        </>
    )
}