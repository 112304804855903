import {Paper, Container, Typography, Button, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";

export const Lander = ({answers}) => {

    const formRef = useRef(null);

    useEffect(() => {
        if(Object.keys(answers).length > 0 && document.forms["form"]) {
            console.log(answers)
            // console.log(answers.firstName) //first_name
            // console.log(answers.lastName) //last_name
            console.log(answers.phoneNumber) //phone
            // console.log(answers.address.structured_formatting.main_text) //address_street
            // console.log(answers.address.terms[2].value) //city
            // console.log(answers.address.terms[3].value) //state
            // console.log(answers['8216694'].text) //bankruptcy
            // console.log(answers['8216697a'].text) //Is the Mortgage up to date?
            // console.log(answers['8216697b']?.text) //Prop Tax Paid on time for Past 3 years?
            // console.log(answers['8216698'].text) //# of Homeowners
            setTimeout(() => {
                console.log('submitting...')
                if(document.forms["form"].onsubmit &&
                    !document.forms["form"].onsubmit())
                {
                    return;
                }
                document.forms["form"].submit();
                localStorage.clear();
            }, 500)
        }
    }, [answers, formRef])

    return (
        <>
            <form name="form" ref={formRef}
                  action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                  method="POST"
            >
                {Object.keys(answers).length > 0 && <>
                    <input type="hidden" name="oid" value="00D5e000001BYNr"/>
                    <input type="hidden" name="retURL" value="https://state-upgrades.zachphelps.com/done"/>
                    <label
                        style={{display: "none"}}
                        htmlFor="phone">First Name</label>
                    <input style={{display: "none"}}
                           id="phone"
                           maxLength="40"
                           name="phone"
                           size="20"
                           value={answers.phoneNumber}
                           onChange={() => {
                           }}
                           type="text"
                    />
                    {/*<label*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    htmlFor="first_name">First Name</label>*/}
                    {/*<input style={{display: "none"}}*/}
                    {/*       id="first_name"*/}
                    {/*       maxLength="40"*/}
                    {/*       name="first_name"*/}
                    {/*       size="20"*/}
                    {/*       value={answers.firstName}*/}
                    {/*       onChange={() => {*/}
                    {/*       }}*/}
                    {/*       type="text"*/}
                    {/*/>*/}
                    {/*<label style={{display: "none"}} htmlFor="last_name">Last Name</label><input*/}
                    {/*style={{display: "none"}}*/}
                    {/*id="last_name"*/}
                    {/*maxLength="80"*/}
                    {/*name="last_name" size="20"*/}
                    {/*value={answers.lastName}*/}
                    {/*onChange={() => {*/}
                    {/*}}*/}
                    {/*type="text"/>*/}
                    {/*<label style={{display: "none"}} htmlFor="city">City</label><input style={{display: "none"}}*/}
                    {/*                                                                   id="city"*/}
                    {/*                                                                   maxLength="40" name="city"*/}
                    {/*                                                                   size="20"*/}
                    {/*                                                                   value={answers.address.terms[2].value}*/}
                    {/*                                                                   onChange={() => {*/}
                    {/*                                                                   }}*/}
                    {/*                                                                   type="text"/>*/}
                    {/*<label style={{display: "none"}} htmlFor="state">State/Province</label><input*/}
                    {/*style={{display: "none"}}*/}
                    {/*id="state" maxLength="20"*/}
                    {/*name="state"*/}
                    {/*value={answers.address.terms[3].value}*/}
                    {/*onChange={() => {*/}
                    {/*}}*/}
                    {/*size="20" type="text"/>*/}

                    {/*<label*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    htmlFor="bankruptcy"*/}
                    {/*></label>*/}
                    {/*<input*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    id="bankruptcy"*/}
                    {/*    maxLength="40"*/}
                    {/*    name="bankruptcy"*/}
                    {/*    size="20" type="text"*/}
                    {/*    value={answers['8216694'].text}*/}
                    {/*    onChange={() => {*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*{answers['8216697a'] && <label*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    htmlFor="Is the Mortgage up to date?"*/}
                    {/*></label>}*/}
                    {/*{answers['8216697a'] && <input*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    id="Is the Mortgage up to date?"*/}
                    {/*    maxLength="40"*/}
                    {/*    name="Is the Mortgage up to date?"*/}
                    {/*    size="20" type="text"*/}
                    {/*    value={answers['8216697a'].text}*/}
                    {/*    onChange={() => {*/}
                    {/*    }}*/}
                    {/*/>}*/}

                    {/*{answers['8216697b'] && <label*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    htmlFor="Prop Tax Paid on time for Past 3 years?"*/}
                    {/*></label>}*/}
                    {/*{answers['8216697b'] && <input*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    id="Prop Tax Paid on time for Past 3 years?"*/}
                    {/*    maxLength="40"*/}
                    {/*    name="Prop Tax Paid on time for Past 3 years?"*/}
                    {/*    size="20" type="text"*/}
                    {/*    value={answers['8216697b'].text}*/}
                    {/*    onChange={() => {*/}
                    {/*    }}*/}
                    {/*/>}*/}

                    {/*<label*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    htmlFor="# of Homeowners"*/}
                    {/*></label>*/}
                    {/*<input*/}
                    {/*    style={{display: "none"}}*/}
                    {/*    id="# of Homeowners"*/}
                    {/*    maxLength="40"*/}
                    {/*    name="# of Homeowners"*/}
                    {/*    size="20" type="text"*/}
                    {/*    value={answers['8216698'].text}*/}
                    {/*    onChange={() => {*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<input type="submit" name="submit"/>*/}
                </>}
            </form>
            <Container maxWidth="md" sx={{py: 4}}>
                <Paper sx={{p: 4, borderRadius: 1.5, alignItems: "center", justifyContent: "center"}} variant="outlined">
                    <Stack alignItems={"center"}>
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            align='center'
                            sx={{ mb: 3 }}
                        >
                            Thanks 🙌
                        </Typography>
                        <Typography variant="h5" textAlign={"center"}>
                            We will be reaching out to you shortly!
                            <br/>
                        </Typography>
                        <Button sx={{mt: 3}} href={"https://www.stateupgrades.com"} >Visit our website</Button>
                    </Stack>
                </Paper>
            </Container>
        </>

    )
}