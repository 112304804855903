
const questions = [
    {
        id: '8216692',
        question: 'Has your home insurance premium risen in the last year?',
        answers: [
            { text: 'Yes', nextQuestion: '/8216693' },
            { text: 'No', nextQuestion: '/8216693' }
        ]
    },
    {
        id: '8216693',
        question: 'Are you on a fixed or limited income?',
        answers: [
            { text: 'Yes', nextQuestion: '/pre-qualified' },
            { text: 'No', nextQuestion: '/pre-qualified' }
        ]
    },
    {
        id: '8216694',
        question: 'Have you filed for bankruptcy in the past 2 years?',
        answers: [
            { text: 'Yes', nextQuestion: '/8216695' },
            { text: 'No', nextQuestion: '/8216695' }
        ]
    },
    {
        id: '8216695',
        question: 'Are you current on your property and mortgage taxes?',
        answers: [
            { text: 'Yes', nextQuestion: '/8216696' },
            { text: 'No', nextQuestion: '/8216696' }
        ]
    },
    {
        id: '8216696',
        question: 'Do you have a mortgage?',
        answers: [
            { text: 'Yes', nextQuestion: '/8216697a' },
            { text: 'No', nextQuestion: '/8216697b' }
        ]
    },
    {
        id: '8216697a',
        question: 'Is the mortgage up to date?',
        answers: [
            { text: 'Yes', nextQuestion: '/qualified' },
            { text: 'No', nextQuestion: '/qualified' }
        ]
    },
    {
        id: '8216697b',
        question: 'Have you been on time with your mortgage payments for the past 3 years?',
        answers: [
            { text: 'Yes', nextQuestion: '/qualified' },
            { text: 'No', nextQuestion: '/qualified' }
        ]
    },
    {
        id: '8216698',
        question: 'How many homeowners on on the title?',
        answers: [
            { text: '1', nextQuestion: '/verify-name-and-address' },
            { text: '2', nextQuestion: '/verify-name-and-address' },
            { text: '3', nextQuestion: '/verify-name-and-address' }
        ]
    },
];

export default questions;