import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, Container, Stack, Typography} from "@mui/material";
import {motion} from "framer-motion";

const improvementTypes = [
    'Roof',
    'Windows',
    'Doors',
    'AC',
    'Other'
];
export const GetImprovementTypes = ({answers, setAnswers}) => {
    const [selectedImprovementTypes, setSelectedImprovementTypes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedImprovementTypes(answers.improvementTypes ?? [])
    }, [answers])

    return (
        <Container
            maxWidth="md"
            sx={{py: 4, justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%'}}
        >
            <motion.div
                key='get-phone-number'
                initial={{ y: '15%' }}
                animate={{ y: 0 }}
                exit={{ x: '-100%' }}
                transition={{ duration: 0.65, ease: "easeInOut" }}
            >
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    justifyContent='center'
                    align='center'
                    sx={{ mb: 3 }}
                >
                    What's your primary safety concern for improvement? (Select all that apply)
                </Typography>

                <Box display="flex" justifyContent="center" flexWrap="wrap">
                    {improvementTypes.map((improvementType) => (
                        <Button
                            variant={selectedImprovementTypes.includes(improvementType) ? "contained" : "outlined"}
                            onClick={() => {
                                if (selectedImprovementTypes.includes(improvementType)) {
                                    setSelectedImprovementTypes(selectedImprovementTypes.filter((type) => type !== improvementType))
                                    return;
                                }
                                setSelectedImprovementTypes([...selectedImprovementTypes, improvementType])
                                setAnswers({...answers, improvementTypes: [...selectedImprovementTypes, improvementType]})
                            }}
                            sx={{m: 1, py: 3, px: 4}}
                            key={improvementType}
                        >
                            {improvementType}
                        </Button>
                    ))}
                </Box>

                <Stack spacing={3} direction="row" sx={{mt: 4, justifyContent:"center"}}>
                    <Button onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        disabled={selectedImprovementTypes.length === 0}
                        onClick={() => navigate('/8216698')}
                    >
                        Next
                    </Button>
                </Stack>

            </motion.div>
        </Container>
    )
}