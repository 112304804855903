import {Button, Container, Stack, Typography} from "@mui/material";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

export const Question = ({question, answers, setAnswers}) => {

    const navigate = useNavigate();

    const [selectedAnswer, setSelectedAnswer] = useState(null);

    useEffect(() => {
        setSelectedAnswer(answers[question.id] ?? null)
    }, [answers, question])

    return (
        <Container
            sx={{py: 4, justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%'}}
        >
            <motion.div
                key={question.id}
                initial={{ y: '15%' }}
                animate={{ y: 0 }}
                exit={{ x: '-100%' }}
                transition={{ duration: 0.65, ease: "easeInOut" }}
            >
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    justifyContent='center'
                    align='center'
                    sx={{ mb: 3 }}
                >
                    {question.question}
                </Typography>

                <Stack direction="column" alignItems="center">
                    {question.answers.map((answer, index) => (
                        <Button
                            key={index}
                            onClick={() => {
                                setSelectedAnswer(answer)
                                setAnswers({...answers, [question.id]: answer})
                                navigate(answer.nextQuestion)
                            }}
                            variant={selectedAnswer && answer.text === selectedAnswer.text ? "contained" : "outlined"}
                            disableElevation
                            color="primary"
                            sx={{
                                mt: 2,
                                maxWidth: '400px',
                                py: 2,
                                minWidth:{xs: '100%', sm: '400px'},
                                fontSize: '1.1rem',
                                '&:hover': {
                                    opacity: 0.9
                                }
                            }}
                        >
                            {answer.text}
                        </Button>
                    ))}
                </Stack>

                <Stack spacing={3} direction="row" sx={{mt: 4, justifyContent:"center"}}>
                    {question.id != "8216692" && <Button onClick={() => navigate(-1)}>
                        Back
                    </Button>}
                    {/*<Button*/}
                    {/*    disabled={!selectedAnswer}*/}
                    {/*    variant="contained"*/}
                    {/*    disableElevation*/}
                    {/*    onClick={async () => {*/}
                    {/*        navigate(selectedAnswer.nextQuestion)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Next*/}
                    {/*</Button>*/}
                </Stack>

            </motion.div>
        </Container>

    )
}