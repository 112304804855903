import {Button, Container, Paper, Stack, Typography} from "@mui/material";

export const NotQualified = () => {
    return (
        <Container maxWidth="md" sx={{py: 4}}>
            <Paper sx={{p: 4, borderRadius: 1.5, alignItems: "center", justifyContent: "center"}} variant="outlined">
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    align='center'
                    sx={{ mb: 3 }}
                >
                    You don't currently qualify for any of our programs.
                </Typography>
                <Typography variant="body1">
                    Oh no! It looks like you don't currently qualify for any of our programs. However, we're here to help
                    you. We can help you find the best financing options for your home improvement project, and we can
                    help you take advantage of programs that can save you even more.
                </Typography>
                <Stack alignItems="center">
                    <Button variant="contained" sx={{mt: 3}} onClick={() => {}}>
                        Schedule a Call
                    </Button>
                </Stack>

            </Paper>

        </Container>
    )
}