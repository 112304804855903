import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import {useState, useMemo, useRef, useEffect} from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {motion} from "framer-motion";
import {Button, Container, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";


const GOOGLE_MAPS_API_KEY = 'AIzaSyC_G41bRZ4HKG0cxLOTElatucbkn8mDbuE';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export const VerifyNameAndAddress = ({answers, setAnswers}) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    const navigate = useNavigate();

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&types=address&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    useEffect(() => {
        if(answers.address) {
            setValue(answers.address)
            setInputValue(answers.address)
        }
        if(answers.firstName) {
            setFirstName(answers.firstName)
        }
        if(answers.lastName) {
            setLastName(answers.lastName)
        }
    }, [answers])

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue, types: ["address"], componentRestrictions: { country: 'us' } }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Container
            sx={{py: 4, justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%'}}
        >
            <motion.div
                key='get-address'
                initial={{ y: '15%' }}
                animate={{ y: 0 }}
                exit={{ x: '-100%' }}
                transition={{ duration: 0.65, ease: "easeInOut" }}
            >
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    justifyContent='center'
                    align='center'
                    sx={{ mb: 3 }}
                >
                    What's your name and address?
                </Typography>

                <TextField
                    label={"First Name"}
                    variant="outlined"
                    fullWidth
                    sx={{mb: 2}}
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                        setAnswers({...answers, firstName: e.target.value})
                    }}
                />

                <TextField
                    label={"Last Name"}
                    variant="outlined"
                    fullWidth
                    sx={{mb: 2}}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value)
                        setAnswers({...answers, lastName: e.target.value})
                    }}
                />

                <Autocomplete
                    id="google-map-demo"
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    noOptionsText="No locations"
                    onChange={(event, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                        setAnswers({...answers, address: newValue})
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={"Address"} fullWidth />
                    )}
                    renderOption={(props, option) => {
                        const matches =
                            option.structured_formatting.main_text_matched_substrings || [];

                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length]),
                        );

                        return (
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                        {parts.map((part, index) => (
                                            <Box
                                                key={index}
                                                component="span"
                                                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                            >
                                                {part.text}
                                            </Box>
                                        ))}

                                        <Typography variant="body2" color="text.secondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />

                <Stack spacing={3} direction="row" sx={{mt: 4, justifyContent:"center"}}>
                    <Button onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    <Button
                        disabled={value === null || firstName === '' || lastName === ''}
                        variant="contained"
                        disableElevation
                        onClick={() => {
                            navigate('/done')
                        }}
                    >
                        Next
                    </Button>
                </Stack>

            </motion.div>
        </Container>
    );
}