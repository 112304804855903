import {motion} from "framer-motion";
import {Button, Container, Stack, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useState, forwardRef, useEffect} from "react";

export const GetPhoneNumber = ({answers, setAnswers}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (answers.phoneNumber) {
            setPhoneNumber(answers.phoneNumber)
        }
    }, [answers])

    const handleChange = (event) => {

        console.log(event.target.value)
        const { value } = event.target;
        // remove all non-numeric characters from input
        const formattedPhoneNumber = value.replace(/\D/g, '');

        // format phone number as (123) 456-7890
        if (formattedPhoneNumber.length > 6 && formattedPhoneNumber.length <= 10) {
            setPhoneNumber(formattedPhoneNumber.replace(/(\d{3})(\d{0,3})(\d{0,4})/, '($1) $2-$3'));
            setAnswers({...answers, phoneNumber: formattedPhoneNumber.replace(/(\d{3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')})
        }
        else {
            setPhoneNumber(formattedPhoneNumber)
            setAnswers({...answers, phoneNumber: formattedPhoneNumber})
        }
    };

    return (
        <Container
            sx={{py: 4, justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%'}}
        >
            <motion.div
                key='get-phone-number'
                initial={{ y: '15%' }}
                animate={{ y: 0 }}
                exit={{ x: '-100%' }}
                transition={{ duration: 0.65, ease: "easeInOut" }}
            >
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    justifyContent='center'
                    align='center'
                    sx={{ mb: 3 }}
                >
                    What's your phone number?
                </Typography>

                <TextField
                    fullWidth
                    value={phoneNumber}
                    onChange={handleChange}
                    placeholder="(123) 456-7890"
                    variant="outlined"
                    inputProps={{
                        maxLength: 14,
                        style: { textAlign: 'center' }
                    }}
                />

                <Stack spacing={3} direction="row" sx={{mt: 4, justifyContent:"center"}}>
                    <Button onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    <Button
                        disabled={phoneNumber.length !== 14}
                        variant="contained"
                        disableElevation
                        onClick={() => navigate('/done')}
                    >
                        Next
                    </Button>
                </Stack>

            </motion.div>
        </Container>
    );
}