import React from 'react';
import logo from '../assets/State-Upgrades.png';
import './Header.css';
import {Button} from "@mui/material";

export const Header = () => {
    return (
        <header className='Header'>
            <a href="https://www.stateupgrades.com">
                <img src={logo} alt="Logo" className='Logo'/>
            </a>
            <Button href={`tel:${"18557064020"}`} className="cta-btn">Call Now:  +1 (855) 706-4020</Button>
        </header>
    );
};